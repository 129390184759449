@import url(https://fonts.googleapis.com/css?family=Roboto:500);
html {
  font-family: "Helvetica Neue Light", "Helvetica Neue", Arial, sans-serif;
  margin: 0px;
  padding: 0;
  height: 100%;
  background-color: rgb(252, 252, 252);
}

body {
  font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
  margin: 0px;
  height: 100%;
}

#root {
  height: 100%;
  display: flex;
  flex-direction: column;
}

/* HEADER */
.header_container {
  height: 80px;
  z-index: 1030;
  border-bottom: solid 1px rgba(196, 196, 196, 0.49)
}
.logo {
  margin-top: 6px;
  margin-left: 20px;
}

.searchBar {
  display: inline-flex;
  position: relative;
  border: 1px solid #ccc;
  height: 30px;
  width: 500px;
  margin-top: 25px;
  border-radius: 5px;
  overflow: hidden;
}

.searchBar input {
  border: 0;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  flex: 1 1;
}

.searchBar i {
  text-align: center;
  padding: 0.4rem 0.8rem 0.8rem 0.8rem;
  background-color: #304355;
  color: white;
  border-radius: 2px;
}

.searchBar i:hover {
  cursor: pointer;
  background-color: #355268;
}

.profile {
  margin-right: 20px;
  margin-top: 16px;
  display: flex;
  grid-gap: 10px;
  gap: 10px
}

/* SIDEBAR */
.sidebar_container {
  min-width: 90px;
  max-width: 90px;
  text-align: center;
  background: #1C3144;
  color: #fff;
  transition: all 0.3s;
  z-index: 1000;
}

.sidebar_container a.active {
  background: #304355;
}

.sidebar_container a {
  color: white;
  display: block;
  padding: 12px 5px;
  text-align: center;
  overflow: hidden;
}

.sidebar_container p {
  margin-top: 3px;
  margin-bottom: 0px;
  font-size: 0.8em;
}

.sidebar_container a:hover {
  color: white;
  background: #304355;
  -webkit-text-decoration-line: none;
          text-decoration-line: none;
  cursor: pointer;
}

.sidebar_container.hidden {
  margin-left: -90px;
}

/* BODY */
.body {
  background-color: rgb(252, 252, 252);
}

.body_content_wrapper {

}

/* DEBATE CARD */
.debate_card {
  cursor: pointer;
  background-color: rgba(0,0,0,0) !important;
  max-width: 240px;
  min-width: 220px !important;
  min-height: 360px;
  box-shadow: 0px 4px 8px 2px rgba(171, 171, 171, 0.2);
}

.debate_card:hover {
  box-shadow: 0px 3px 5px 3px rgba(171, 171, 171, 0.5);
  transition-duration: 0.2s;
}

.debate_title {
  font-size: 16px;
  font-weight: 500;
  color: black;
}

.debate_info {
  grid-gap: 10px;
  gap: 10px;
  font-weight: 100;
  color: dimgrey;
  white-space: pre-wrap;
  font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
}

.debatesContent {
  margin-left: 0px !important;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  grid-gap: 30px;
  gap: 30px;
}

.thumbnail_wrapper {
}

.thumbnail_div {
  height: 75%;
  width: 216px;
  background-color: #a4becb75;
}

.thumbnail_div img {
  position: relative;
  top: 0;
  left: 0;
  width: 130px;
  height: 130px;
  margin-left: auto;
  margin-right: auto;
}

.thumbnail_title {
  overflow: hidden;
  margin-top: 16px;
  margin-bottom: 8px;
  height: 20px;
  width: 100%;
  padding-left: 2px;
}

.thumbnail_title span {
  text-align: left;
  font-size: 16px;
  font-weight: 600;
  color: black;
  line-height: 1.1;
}

.thumbnail_bottom img {
  height: 20px;
  width: 20px;
  margin-right: 5px;
}

/* HOME SECTION */
.homeSection_title, .homeSection hr {
  margin-left: 19px;
}

.homeSection_debates {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  grid-gap: 30px;
  gap: 30px;
}

/* DEBATE FORM */
.addDebateModal {
  max-width: 680px !important;
}

.addDebateModal.faded {
  -webkit-filter: brightness(0.3);
          filter: brightness(0.3);
}

.confirmModal {
  margin-top: 9rem !important;
}

.addDebateModal .modal-header .close, .confirmModal .modal-header .close {
  position: absolute;
  right: 7px;
  top: 6px;
}

.addDebateModal .modal-content {
  align-items: center;
  max-height: 550px;
}

.addDebateModal.overflow .modal-content {
  overflow-x: hidden;
  overflow-y: auto;
}

.addDebateModal.fixedHeight .modal-content {
  height: 470px;
}

.addDebateModal .modal-body {

}

.nextButton, .previousButton {
  width: 150px;
}

.nextButtonWide {
  width: 190px;
}

.special_button:hover {
  color: #007bff !important;
  background-color: #007bff1a !important;
}

.debate_form_control {
  display: flex;
  flex-direction: column;
  margin-bottom: 14px;
}

.debate_form_control p {
  font-size: 13px;
}

.skip_text {
  font-size: 14px;
  cursor: pointer;
  position: absolute;
  bottom: 15px;
  right: 7px;
  color: #706f6f;
  font-weight: 400;
}

.skip_text:hover {
  text-decoration: underline;
}

.custom-switch {
  padding-left: 20px !important;
}

.debate_form_control label {
  font-weight: 500;
}

.debate_form_control_checkbox {
}

.debate_form_control_checkbox input{
  position: relative;
  top: 2px;
}

.debate_form_control_checkbox label{
  font-weight: 500;
}

.debate_form_control_select {
  font-weight: 500;
}

.title_input {
  transition: border-color ease-in-out .3s;
}

.input_error-enter {
  opacity: 0.01;
}

.input_error-enter.input_error-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}

.input_error-leave {
  opacity: 1;
}

.input_error-leave.input_error-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

.debateType_div {
  position: relative;
  height: 250px;
  width: 190px;
  border: 1px solid #c2c2c2;
  border-radius: 12px;
  box-shadow: 0px 4px 7px 2px rgba(114, 114, 114, 0.2);
  background-color: rgba(255, 255, 255, 1);
  cursor: pointer;
}

.debateType_div:hover {
  box-shadow: 0px 4px 7px 5px rgba(114, 114, 114, 0.2);
}

.debateType_div.tree img {
  position: absolute;
  top: 12px;
  left: 0px;
  width: 210px;
  transition: opacity 400ms linear;
}

.debateType_div .hiddenText {
  opacity: 0%;
  transition: opacity 400ms linear;
}

.debateType_div.free img {
  position: absolute;
  top: 15px;
  left: -2px;
  width: 194px;
  opacity: 1;
  transition: opacity 400ms linear;
}

.debateType_div.hovered img {
  opacity: 0%;
}

.debateType_div.hovered .hiddenText {
  opacity: 1;
}

.hiddenText {
  text-align: justify;
  font-size: 14px;
  color: #717171;
  font-weight: 400;
}

.debateType_div.clicked {
  box-shadow: 0 0 0 0.2rem #416275;
}

/* DEBATE MODAL */
.debate_modal_bottom_header {
  margin-top: 15px;
  display: flex;
  font-size: 15px;
  color: dimgrey;
}

.debate_modal_bottom_header img {
  height: 21px;
  width: 21px;
  margin-right: 3px;
  position: relative;
  right: 1px;
  bottom: 2px;
}

.info_debate {
  line-height: 29px;
}

.subscribeDiv {
  margin-left: 15px;
}

.subscribeDiv_text {
  border-radius: 4px;
  width: 120px;
  height: 32px;
  cursor: pointer;
}

.subscribeDiv_text p {
  padding: 12px 15px 12px 15px;
  letter-spacing: 1px;
  font-weight: 600;
  line-height: 50%;
  font-size: 13px;
  -webkit-transition: all 0.1s ease-out;
  text-align: center;
}

.subscribeDiv_text.subscribed {
  border: solid 1px #3e4b56;
  color: white;
  background-color: #3e4b56;
}

.subscribeDiv_text.subscribed:hover {
  background-color: #cf3e3e;
  border: solid 1px #cf3e3e;
  color: white;
}

.subscribeDiv_text.unsubscribed {
  border: solid 1px #1c3144;
  color: #1c3144;
  background-color: white;
}

.subscribeDiv_text.unsubscribed:hover {
  background-color: #e7f2f8;
}

.debate_detail_info {
  display: flex;
  justify-content: space-around;
  padding: 25px 15px;
}

.debate_detail_info img {
  height: 20px;
  width: 20px;
  margin-right: 9px;
  position: relative;
  bottom: 1px;
}

.info_column {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  grid-gap: 40px;
  gap: 40px;
}

.addSpan {
  color: #0645AD ;
}

.addSpan:hover {
  cursor: pointer;
  text-decoration: underline;
}

.rootArgumentRow {
  /*border: solid 1px #b4b4b4; */
}

/* FILTER BOX */
.filterContainer {
  width: 285px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border: solid 1px #e0e0e0;
  background-color: white;
  font-size: 15px;
  font-weight: 500;
  color: #434343;
}

.filterContainerSmall {
  margin-left: 33px !important;
  margin-right: 33px;
  border: solid 1px #e0e0e0;
  background-color: white;
}

.filtersButton {
  margin-left: 33px;
  color: #304355;
  cursor: pointer;
  font-weight: 600;
}

.filtersButton:hover {
  color: #005476;
}

.filterByName {
  height: 30px;
}

.filterSelect {
  padding: 1px;
  font-size: 14px;
  height: 30px;
}

.tagify {
  border-color: #6c757d!important;
  border-radius: 0.25rem!important;
}

/* FILTERED DEBATES CONTENT */
.filteredDebatesContent {
}

.filterDiv {
}


/* BUTTONS */
.button0 {
  cursor: pointer;
  height: 44px;
  width: 134px;
  display: inline-block;
  border-radius: 2em;
  box-sizing: border-box;
  text-decoration: none;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  line-height: 44px;
  color: #FFFFFF;
  background-color: #4eb5f1;
  text-align: center;
  transition: all 0.2s;
  margin-top: 30px;
  font-size: 17px;
}

.button0:hover{
  background-color:#4095c6;
  color: #FFFFFF;
  text-decoration: none;
}

/* GOOGLE BUTTON */

.g-sign-in-button {
  webkit-border-radius: 1px;
  border-radius: 1px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
  transition: background-color .218s,border-color .218s,box-shadow .218s;
  -webkit-user-select: none;
  -webkit-appearance: none;
  background-image: none;
  cursor: pointer;
  outline: none;
  overflow: hidden;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  width: 230px;
  height: 50px;
  background-color: #4285f4;
  border: none;
  color: #fff;
}

.g-sign-in-button:hover {
  box-shadow: 0 0 3px 3px rgba(66, 133, 244, 0.3);
  background-color: #4285f4;
}

.g-sign-in-button:active {
  background-color: #3367d6;
}

.g-sign-in-button .content-wrapper {
  height: 100%;
  width: 100%;
  border: 1px solid transparent;
}

.g-sign-in-button .logo-wrapper {
  padding: 11px 15px 15px 15px;
  background: #fff;
  width: 48px;
  height: 100%;
  border-radius: 1px;
  display: inline-block;
}

.g-sign-in-button .text-container {
  font-family: Roboto,arial,sans-serif;
  font-weight: 500;
  letter-spacing: .21px;
  font-size: 16px;
  line-height: 48px;
  vertical-align: top;
  border: none;
  display: inline-block;
  text-align: center;
  width: 180px;
}

/* PROFILE */
.profilePic {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
  transition: background-color .218s,border-color .218s,box-shadow .218s;
  -webkit-user-select: none;
  -webkit-appearance: none;
  height: 50px;
  width: 50px;
  cursor: pointer;
  z-index: 1000;
}


.profilePic:hover {
  box-shadow: 0 0 3px 3px rgba(12, 23, 42, 0.3);
}

#hover-tooltip .arrow {
  display: none !important;
}

#hover-tooltip-over .arrow {
  display: none !important;
}

#hover-tooltip-over {
  z-index: 1500 !important;
}


.small_add_debate {
  color: #056ad7;
  cursor: pointer;
}

.small_add_debate:hover {
  color: #257bec;
}

.search_icon {
  color: white;
  background-color: #3e4b56;
  border-radius: 5px;
  cursor: pointer;
}

.search_icon:hover {
  background-color: #2d3e4a;
}

.arrow_back {
  cursor: pointer;
}

/* POPULAR TAGS */
.popularTags.tagify {
  cursor: default;
}

.popularTags .tagify__tag__removeBtn {
  display: none;
}

.popularTags .tagify__input {
  display: none;
}

.popupBox {
  border: solid 1px #eadfe7;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.2);
  background-color: white;
  cursor: pointer;
  color: deepskyblue;
  z-index: 1060;
}

.popupBox:hover {
  background-color: #fbfbfb;
}

/* DEBATE INTERFACE */

.foreignObject {
  position: relative;
  width: 250px;
  height: 170px;
  background: transparent;
  transition: width 100ms, height 100ms;
  -webkit-transition: width 100ms, height 100ms;
  -moz-transition: width 100ms, height 100ms;
  -o-transition: width 100ms, height 100ms;
  overflow: visible;
}

.foreignObject:not(.help):hover > .contentDiv {
 /* box-shadow: 0px 0px 2px 4px rgba(0, 0, 0, 0.1); */
}

.foreignObject.clicked, foreignObject.initial {
  width: 345px;
  height: 270px;
}

.foreignObject.clicked .contentDiv {
  width: 325px;
  height: 250px;
}

.foreignObject.clicked .nodeText {
  height: 177px;
  overflow-y: auto;
  overflow-x: hidden;
}

.foreignObject.clicked #likeDiv {
  width: 325px;
}

.foreignObject.clicked .nodeExpandDiv {
  margin-top: 105px;
}

.foreignObject.clicked .nodeImg {
  top: 125px;
}

.foreignObject.faded {
  -webkit-filter: blur(10px) saturate(60%) sepia(30%);
}

.contentDiv {
  stroke: #fff;
  stroke-width: 1.5px;
  background: transparent;
  border-radius: 25px;
  height: 150px;
  width: 230px;
  padding-left: 10px;
  padding-top: 5px;
  font: 15px 'Helvetica';
  line-height: 19px;
  margin: 10px auto 0px auto;
  transition: background-color 1000ms linear, border 1000ms linear;
  /* box-shadow: 0px 0px 2px 4px rgb(70, 151, 221); */
}

.contentDiv.initial {
    width: 345px;
    height: 270px;
}

.contentDiv.initial:before {
    /*background: none;
    border-radius: 30px;
    border-color: inherit;
    border-width: 4px;
    border-style: solid;
    content: "";
    display: block;
    position: absolute;
    top: 7px;
    left: 7px;
    right: 7px;
    bottom: 7px;
    pointer-events: none;
    box-shadow: 2px 2px 4px 3px rgba(0, 0, 0, 0.14);*/
}

.contentDiv.highlighted {
  box-shadow: 0px 0px 2px 4px rgba(0, 0, 0, 0.2);
}

.nodeText {
  height: 93px;
  margin-top: 6px;
  margin-left: 2px;
  margin-right: 15px;
  cursor: default;
}

.trunkate {
  overflow-wrap: break-word;
}

.likeDiv {
  position: fixed;
  -webkit-transform-origin: 0 0;
  z-index: 0;
  bottom: 9px;
  height: 41px;
  border-radius: 45px 45px 25px 25px;
  width: 230px;
  padding-left: 16px;
  padding-top: 6px;
  opacity: inherit;
  margin-left: 10px;
  cursor: default;
}

.likeDiv button {
  background: transparent;
  border-style: none !important;
}

.pin_div {
  float: right;
  left: 10px;
  bottom: 4px;
  width: 26px;
  margin-right: 6px;
  padding: 4px;
  border-radius: 15px;
  transition: background-color 0.1s ease-out;
}

.pin_div:hover {
  background-color: rgba(255, 255, 255, 0.12);
}

.pin_div.hidden {
  display: none;
}

#pinDivImg {
  float: right;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.nodeExpandDiv {
  float: right;
  width: 24px;
  height: 28px;
  margin-right: 1px;
  margin-top: 53px;
  padding: 4px;
  border-radius: 15px;
  transition: background-color 0.1s ease-out;
}

.nodeExpandDiv:hover {
  background-color: rgba(255, 255, 255, 0.12);
}

.nodeImg {
  position: absolute;
  right: 12px;
  top: 72px;
  width: 25px;
  height: 25px;
  cursor: pointer;
  transition: all 0.5s;
}

.nodeImg.hidden {
  opacity: 0;
}

.nodeImg.rotateIn {
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
  opacity: 1;
}

.nodeImg.rotateOut {
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
  opacity: 0%;
}

.nodeImageDiv {
  margin-right: 10px;
  margin-top: 5px;
  width: 100%;
  cursor: pointer;
}

.nodeImageDiv:hover{
  text-decoration: underline;
}

.nodeImageDiv span {
  margin-left: 5px;
  font-weight: 600;
}

.path.links {
  transition: stroke-width 500ms linear;
}

.path.links.hidden {
  display: none;
}

.path.links.attack {
  opacity: 1;
  marker-end: url("#endAttack");
}

.path.links.support {
  opacity: 1;
  marker-end: url("#endSupport");
}

.path.links.attack.hovered {
  opacity: 1;
  stroke: black;
  marker-end: url("#highlightAttack");
}

.path.links.support.hovered {
  opacity: 1;
  stroke: black;
  marker-end: url("#highlightSupport");
}

.path.links.attack.faded {
  opacity: 0.2;
  -webkit-filter: blur(2px);
          filter: blur(2px);
}

.path.links.support.faded {
  opacity: 0.2;
  -webkit-filter: blur(2px);
          filter: blur(2px);
}

.custom-menu {
  display: none;
  z-index: 1000;
  position: absolute;
  overflow: hidden;
  border: 1px solid #CCC;
  white-space: nowrap;
  font-family: sans-serif;
  background: #FFF;
  color: #333;
  border-radius: 5px;
  padding: 0px;
  list-style-type: none;
  box-shadow: 0px 0px 13px 0px rgba(114, 114, 114, 0.2);
}

.custom-menu li {
  padding: 8px 12px;
  cursor: pointer;
  list-style-type: none;
}

.custom-menu li:hover {
  background-color: #DEF;
}

.custom-menu hr {
  margin-top: 0;
  margin-bottom: 0;
  border-top: 1px solid #d8d8d8;
}

.trunkate, .trunkatee {
  overflow-wrap: break-word;
}

.trunkateFill, .showLess {
  font-weight: 600;
  cursor: pointer;
}

.showLess {
  margin-left: 3px;
}

.trunkateFill:hover, .showLess:hover {
  text-decoration: underline;
}

#likeAttackObject {
  width: 700px;
  heigth: 200px;
}


#likeAttackDiv {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: 43px;
  border: solid 1px #eadfe7;
  padding: 6px 9px 5px 5px;
  margin-left: 50px;
  margin-top: 20px;
  background-color: white;
  box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}

#likeAttackDiv button {
  background: transparent;
  border-style: none;
}


#showLinkScore {
  width: 500px;
  pointer-events: none;
}

#scoreAttackDiv {
  border: solid 1px #eadfe7;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: 94px;
  margin-left: 30px;
  margin-top: 20px;
  background-color: white;
  overflow-wrap: normal;
  font: 16px 'Helvetica';
  box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  padding: 10px 10px;
}

::-webkit-scrollbar {
  width: 10px;
  margin-top: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.dropdown-toggle {
  background-color: transparent !important;
  border: none !important;
  color: black !important;
  font-size: 150% !important;
  outline: none !important;
}

.dropdown-toggle:focus {
  border: none !important;
  outline: none !important;
}

#settingsButton, #tutorialButton {
  outline: none;
  background: none;
  border: none;
}

#settingsButton img {
  height: 32px;
  -webkit-user-select: none;
  -webkit-user-drag: none;
  -webkit-app-region: no-drag;
}

#tutorialButton img {
  height: 32px;
  -webkit-user-select: none;
  -webkit-user-drag: none;
  -webkit-app-region: no-drag;
}

.button_hover {
  opacity: 0.85;
}

.button_hover.faded {
  -webkit-filter: blur(2px);
          filter: blur(2px);
}

.button_hover:not(.faded):hover {
  opacity: 1;
}

#sidebarCollapse {
  background-color: transparent;
  border: none;
  font-size: 25px;
  color: black;
  outline: none;
}

.interfaceHeader {
  top: 0;
  left: 0;
  white-space: nowrap;
  height: 80px;
}

#settingsBox {
  display: none;
  flex-direction: column;
  border: solid 1px #eadfe7;
  border-radius: 12px;
  width: 300px;
  left: 141px;
  top: 50px;
  position: fixed;
  background-color: white;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.2);
  z-index: 1200;
  padding: 10px 15px;
  grid-gap: 14px;
  gap: 14px;
}

.settings_row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 7px;
  padding: 0px 5px;
}

.settings_row select {
  height: 22px;
  width: 100px;
}

.settings_row span {
  font-size: 13px;
  color: rgba(52, 52, 52, 0.84);
  font-weight: 700;
  line-height: 24px;
  margin-right: 15px;
  width: 35px;
  white-space: nowrap;
}

.settings_row input {
  position: absolute;
  visibility: hidden;
  right: -15px;
}

.settings_header {
  font-size: 15px;
  color: rgba(29, 29, 29, 0.84);
  font-weight: 700;
  line-height: 24px;
  margin-right: 15px;
  width: 35px;
}

.settings_selectors {
  display: flex;
  flex-direction: row;
  grid-gap: 10px;
  gap: 10px;
}


.settings_section hr {
  margin: 2px 0px 8px 0px;
}

.headSelect {
  height: 22px;
  width: 100px;
}

.color-picker {
  border: solid 1px #dadada;
  border-radius: 8px;
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.colorPattern-picker, .colorPattern-picker2 {
  border: solid 1px #dadada;
  border-radius: 8px;
  width: 65px;
  height: 30px;
  cursor: pointer;
  background: white;
}

.color-picker:hover {
  /*box-shadow: 0 0 3px 3px rgba(107, 107, 107, 0.44);*/
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.2);
}

.settings_bottom {
  display: flex;
  justify-content: flex-end;
}

.saveButton {
  border-radius: 4px;
  width: 50px;
  height: 23px;
  border: solid 1px #3e4b56;
  color: white;
  background-color: #3e4b56;
  cursor: pointer;
  padding: 0px;
}

.saveButton p {
  font-weight: 600;
  letter-spacing: 0.4px;
  font-size: 13px;
  -webkit-transition: all 0.1s ease-out;
  text-align: center;
}
.saveButton:hover {
  background-color: #435c6c;
}

.settings_advanced {
  display: flex;
  flex-direction: column;
  margin-top: 7px;
  padding: 0px 5px;
}

.advanced_settings_click span {
  font-size: 13px;
  color: rgba(29, 29, 29, 0.84);
  font-weight: 700;
  line-height: 24px;
  margin-right: 5px;
}

.settings_advanced i {
  font-size: 18px;
  margin-bottom: 2px;
  transition: all 0.2s;
}

.settings_advanced i.rotate {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  display: inline-block;
  margin-bottom: 0px;
}

.advanced_settings_click {
  cursor: pointer;
  display: flex;
  align-items: center;
}

#semanticSelect {
  height: 26px;
  font-size: 12px;
  background-color: #fcfcfc;
  border-radius: 7px;
  outline: none;
  padding: 3px 1px 3px 3px;
  margin-right: 12px;
}


.semantic_select_div {
  display: flex;
  align-items: center;
}

.advanced_hidden {
  display: flex;
  height: 0px;
  transition:height 0.2s ease-out;
  overflow: hidden;
  padding-right: 0px;
  margin: 0px;
}

.advanced_hidden i {
  display: none;
  cursor: pointer;
  position: absolute;
  right: 5px;
  opacity: 0.9;
  transition: none;
  margin: 0px;
  font-size: 20px;
}

.advanced_hidden i:hover {
  opacity: 1;
}

.advanced_hidden.showing {
  height: 35px;
  overflow: visible;
}

.advanced_hidden.showing i {
  display: block;
}

.headSelect {
  display: none;
}

.styles_box {
  display: none;
  position: absolute;
  border: solid 1px #eadfe7;
  border-radius: 12px;
  max-width: 79px;
  background-color: white;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.2);
  z-index: 12000;
  padding: 10px 6px;
  flex-direction: column;
  grid-gap: 6px;
  gap: 6px;
}

#styles_attacks {
  right: 62px;
  top: 115px;
}

#styles_supports {
  right: 62px;
  top: 155px;
}

#colorPatterns {
  right: 12px;
  top: 78px;
}

.style_div {
  border: solid 1px #dadada;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 2px;
}

.style_div.selected {
  border: solid 1px #0796fc;
}

.style_div:hover {
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
}

.style_div img {
  width: 60px;
}

.style_div i {
  margin-left: 3px;
  margin-right: 6px;
}

.rotate_90 {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

#logo {
  position: absolute;
  left: 110px;
  bottom: 33px;
  -webkit-user-select: none;
  -webkit-user-drag: none;
  -webkit-app-region: no-drag;
  z-index: 1000;
}

.bottom-buttons {
  position: absolute;
  bottom: 20px;
  right: 20px;
  display: flex;
  grid-gap: 15px;
  gap: 15px;
  z-index: 1000;
  -webkit-user-select: none; /* Safari */ /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

#addButton {
  background: none;
  border: none;
  outline: none;
}

#addButton img {
  height: 35px;
}

#focusButton, #help-focusButton {
  background: none;
  border: none;
  outline: none;
}

#releaseAllNodesButton, #help-releaseAllNodesButton, #collapseButton {
  background: none;
  border: none;
  outline: none;
}

#releaseAllNodesButton img, #help-releaseAllNodesButton img {
  height: 35px;
}

.image-upload {
  color: black;
}

.image-upload label {
  margin-bottom: 0 !important;
}

.image-upload span {
  font-weight: 600;
}

#file-input, #file-input-create {
  display: none;
}

#divTextarea, #help-divTextarea {
  height: 100px;
  border: 1px solid #ccc;
  margin-right: 5px;
  padding: 5px;
  overflow: auto;
  text-align: justify;
  max-width: 100%;
  min-width: 100%;
  max-height: 500px;
  min-height: 40px;
  transition: all 0.3s;
}

#divTextarea.error, #help-divTextarea.error {
  border: solid 1px #bd2200;
}

.input_error_alert {
  font-weight: 500;
  color: #dc3545;
}

.input_error_alert2 {
  display: none;
  margin-right: 10px;
  font-weight: 500;
  color: #dc3545;
  line-height: 20px;
  -webkit-animation: fadePass 3s linear;
          animation: fadePass 3s linear;
  transition: 3s all;
}


.file-preview {
  background-color: #eeeeee;
  width: 100%;
  height: 30px;
  margin-top: 5px;
  padding: 6px;
  display: flex;
  justify-content: space-between;
}

.progressbar {
  background-color: white;
  border: 1px solid #9e9e9e;
  border-radius: 2px; /* (height of inner div) / 2 + padding */
  padding: 1px;
  width: 100px;
  height: 18px;
}

.progressbar > div {
  background-color: #1C3144;
  width: 0%; /* Adjust with JavaScript */
  height: 14px;
  border-radius: 2px;
  -webkit-animation: fading 1.5s infinite;
          animation: fading 1.5s infinite;
  transition: width 1s;
}

@-webkit-keyframes fading {
  0% {
    opacity: 1;
  }

  50% {
    opacity: .75;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fading {
  0% {
    opacity: 1;
  }

  50% {
    opacity: .75;
  }

  100% {
    opacity: 1;
  }
}

.file-name {
  width: 182px;
  line-height: 18px;
  display: flex;
  overflow: hidden;
}

.file-name2.uploaded {
  cursor: pointer;
}

.file-title {
  font-weight: 600;
  color: #4e4e4e;
  font-size: 12px;
}

.file-name2.uploaded .file-title {
  color: #0072e7;
}

.file-size {
  font-weight: 600;
  color: #6e6e6e;
  font-size: 12px;
  margin-left: 5px;
  margin-top: 2px;
}

.close {
  margin-top: -0.3rem;
}

.hidden_tooltip {
  display: none;
}

.signInTooltip {
  position: absolute;
  border: solid 1px #5c5c5c;
  border-radius: 6px;
  background-color: #191919;
  color: white;
  padding: 7px;
  cursor: default;
  z-index: 9999;
}

#argumentFilesViewModal {
  padding-left: 0 !important;
  width: 75%;
}

.uploadViewer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.75);;
  width: 100%;
  height: 100%;
  text-align: center;
}

.midSection {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 85%;
  align-items: center;
  margin-bottom: 10px;
}

.nextFile, .previousFile {
  color: #bbb;
}

.nextFile:hover, .previousFile:hover {
  color: #5e5e5e;
}


.fileProgress {
  margin-bottom: 5px;
}

.imgDiv {
  width: 100%;
  position: relative;
  height: 100%;
  margin: 0px 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.plyr {
  width: 90%;
}

.uploadViewer .plyr {
  display: none;
}

.uploadViewer .imageFile {
  max-width: 100%;
  max-height: 80%;
  margin: 0 auto;
  display: none;
}

.pdfPreview {
  background: white;
  width: 45%;
  margin: auto;
  border-radius: 10px;
  padding: 15px;
  box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.2);
}

.pdfPreview img {
  height: 102px;
  max-width: 100%;
  display: none;
}

.fileName {
  margin-top: 15px;
  margin-bottom: 12px;
  font-size: 17px;
  font-weight: 600;
}

.closeUploadView {
  width: 100%;
  display: flex;
  flex-flow: row-reverse;
}

.closeButtonUpload {
  color: #ffffff !important;
  font-size: 42px !important;
  font-weight: bold !important;
  margin: 5px !important;
  text-shadow: none !important;
}

.closeButtonUpload:hover {
  color: #aaaaaa !important;
  text-decoration: none !important;
  cursor: pointer !important;
}

.next_previous_buttons {
  display: flex;
  width: 100%;
  height: 65px;
  z-index: 3000;
}

.dot {
  margin: 4px;
  height: 22px;
  width: 22px;
  background-color: #5e5e5e;
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
}

.dot:not(.selected):hover {
  background-color: #848484;
}

.dot.selected {
  background-color: #bbb;
}

.nextFile, .previousFile {
  margin: 3px;
  font-size: 50px;
  cursor: pointer;
}

.argImageView {
  width: 25%;
  height: 100%;
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: white;
  z-index: 1050;
  padding: 24px 0px;
  font-size: 18px;
}

.argImageView span.arg {
  height: 200px;
  overflow-y: auto;
  margin-left: 15px;
}

.likeNode, .dislikeNode {
  outline: none;
  background: transparent;
  border-style: none;
  font-size: 24px;
}

#check-arg-connect {
  white-space: normal;
  overflow-wrap: normal;
  font-size: 14px;
  padding: 14px 14px;
}

#check-arg-connect hr {
}

#check-arg-connect span {
  display: inline-block;
  cursor: default;
  border-radius: 10px;
  font-weight: 600;
  padding: 10px 10px;
  margin-bottom: 15px;
  color: #474747;
}

.check-connect-ask-again {
  display: flex;
  grid-gap: 5px;
  gap: 5px;
  align-items: center;
}
.check-connect-ask-again input {
  margin: 0;
}

.check-arg-bottom {
  display: flex;
  justify-content: space-between;
  padding: 0px 4px;
}

.check-arg-buttons {
  display: flex;
}

#connectConfirm {
  margin-left: 5px;
  background-color: #4e8cd4;
  font-size: 12px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 15px;
  color: white;
  letter-spacing: 1px;
  border-radius: 8px;
}

#connectCancel:hover {
  background-color: white;
  text-decoration: underline;
}

#connectConfirm:hover {
  background-color: #3473b3;
}

.success_debate_span {
  color: #585858;
  font-family: 'Helvetica Neue', sans-serif;
  font-size: 25px;
  font-weight: bold;
  letter-spacing: -1px;
  line-height: 1;
  text-align: center;
  /*text-shadow: 0px 0px 4px #37689047;*/
}

.confirm_span {
  color: #585858;
  font-size: 17px;
  font-weight: 500;
  letter-spacing: -1px;
  line-height: 1;
  text-align: center;
}


#slider-background {
  background: white;
  width: 528px;
  height: 14px;
  border-radius: 10px;
  margin: 8px auto;
}

.range-div {
  height: 14px;
  background-color: #d0d0d0;
}

.range-div.min {
  float: left;
  width: 0px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.range-div.max {
  float: right;
  width: 0px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.slider-draggable {
  width: 22px;
  height: 22px;
  background-color: transparent;
  border: solid 4px white;
  border-radius: 50%;
  position: relative;
  bottom: 4px;
  box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.38);
}

.slider-draggable:hover {
  box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.32);
}

.slider-draggable.min {
  float: right;
  left: 18px;
}

.slider-draggable.max {
  float: left;
  right: 18px;
}

#sliderFilterBox {
  display: none;
  position: absolute;
  left: 69px;
  top: 48px;
  padding: 5px 0;
  width: 565px;
  z-index: 1300;
  background-color: white;
  border: 1px solid rgba(0,0,0,.15);
  border-radius: 4px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.18);
  -webkit-user-select: none; /* Safari */ /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
  font-weight: 500;
  color: #565656;
}

#sliderFilterBox.open {
  display: block;
}

.wrapper {
  -webkit-user-select: none; /* Safari */ /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

/* Tutorial */
#tutorialModal {

}


.startTutorialContent {
  margin: 15% auto;
  max-width: 916px;
  height: 200px;
}

#startTutorialModal span {
  color: #585858;
  font-family: 'Helvetica Neue', sans-serif;
  font-size: 40px;
  font-weight: bold;
  letter-spacing: -1px;
  line-height: 1;
  text-align: center;
  text-shadow: 0px 0px 4px #37689047;
}

.check_tutorial {
  display: flex;
  grid-gap: 5px;
  gap: 5px;
  height: 10px;
  align-items: center;
  left: 20px;
  font-weight: 600;
  color: #585858;
  font-size: 16px;
}

.check_tutorial input {
  margin: 0px 0px 2px 0px;
}

.tutorialModalContent {
  width: 90% !important;
  height: 90%;
  margin: 2% auto;
}

.help-button-exit {
  position: absolute;
  top: 25px;
  left: 25px;
  height: 46px;
  width: 138px;
  display: inline-block;
  border-radius: 2em;
  box-sizing: border-box;
  text-decoration: none;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  line-height: 46px;
  color: #FFFFFF;
  background-color: #cf3e3e;
  text-align: center;
  transition: all 0.2s;
  font-size: 18px;
}

.help-button-exit:hover {
  background-color: #a92e2d;
  color: white;
  text-decoration: none;
}

#help-svg {
  width: 100%;
  height: 100%;
  z-index: 0;
}

.rotatingAnimation {
  -webkit-animation: rotating 4s infinite;
          animation: rotating 4s infinite;
}

.expandingAnimation {
  -webkit-animation: expanding2 3s infinite;
          animation: expanding2 3s infinite;
}

.unpinAll_tooltip {
  position: absolute;
  bottom: 83px;
  right: 91px;
}

.unpinAll_tooltip:after {
  top: 41px;
  right: 11px;
  margin-left: -5px;
  border-color: #2d2d2d transparent transparent transparent;
}

.help-tooltip {
  border: solid 1px #2d2d2d;
  border-radius: 9px;
  text-decoration: none;
  font-family: 'Roboto', sans-serif;
  text-align: center;
  color: rgb(255 255 255);
  font-weight: 500;
  background-color: #2d2d2d;
  padding: 10px 15px;
  font-size: 15px;
}

.tooltipArrow::after {
  content: "";
  position: absolute;
  border-width: 10px;
  border-style: solid;
}

.tooltipArrow.tooltip-bottom::after {
  top: 100%; /* At the bottom of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-color: #2d2d2d transparent transparent transparent;
}

.tooltipArrow.tooltip-left::after {
  top: 50%;
  right: 100%; /* To the left of the tooltip */
  margin-top: -5px;
  border-color: transparent #2d2d2d transparent transparent;
}

.tooltipArrow.tooltip-right::after {
  top: 50%;
  left: 100%; /* To the right of the tooltip */
  margin-top: -5px;
  border-color: transparent transparent transparent #2d2d2d;
}

.tooltipArrow.tooltip-top::after {
  bottom: 100%;  /* At the top of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-color: transparent transparent #2d2d2d transparent;
}

.help-header {
  position: absolute;
  bottom: 78px;
  right: 73px;
  width: 226px;
  display: flex;
  flex-direction: column;
  grid-gap: 14px;
  gap: 14px;
  opacity: 1;
}

.help-header::after {
  top: 98%
}

@-webkit-keyframes rotating {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  5% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  10% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
  }
  15% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg)
  }
  20% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
  }
  25% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
}

@keyframes rotating {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  5% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  10% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
  }
  15% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg)
  }
  20% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
  }
  25% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
}

@-webkit-keyframes rotating2 {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  13% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  26% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
  }
  39% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg)
  }
  52% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
  }
  65% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
}

@keyframes rotating2 {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  13% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  26% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
  }
  39% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg)
  }
  52% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
  }
  65% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
}

.text-faded {
  cursor: default;
  color: #9a9a9a;
  font-weight: 700;
}

.likeTooltip {
  position: absolute;
  bottom: 0px;
  right: 222px;
  width: 326px;
  display: flex;
  flex-direction: column;
}

.likeTooltip::after {
  top: 9px;
  left: 324px;
  border-width: 10px;
  border-color: transparent transparent transparent #2d2d2d;
}

.noHover {
  pointer-events: none;
}

.explain_likeTooltip, .explain_attackTooltip {
  position: absolute;
  bottom: 115px;
  right: -346px;
  width: 326px;
  display: flex;
  flex-direction: column;
  text-align: justify;
}

.darker {
  color: #4E8CD4
}

.lighter {
  color: #9ef1ff
}

.got-it, .ok {
  position: absolute;
  text-align: center;
  bottom: 139px;
  left: 611px;
  border-radius: 8px;
  background-color: #4eb5f1;
  font-size: 17px;
  font-family: 'Roboto', sans-serif;
  color: rgb(255 255 255);
  font-weight: 500;
  width: 80px;
  padding: 7px 15px;
  cursor: pointer;
  -webkit-animation: rotating2 2.2s infinite;
          animation: rotating2 2.2s infinite;
}

.attacked_ok {
  bottom: 130px;
}

.got-it:hover, .ok:hover {
  background-color:#4095c6;
}

.add_attack_tooltip {
  align-content: center;
  position: absolute;
  bottom: 7px;
  right: -195px;
  width: 209px;
  display: flex;
  flex-direction: column;
  text-align: justify;
}

.add_attack_tooltip::after {
  top: 11px;
  right: 206px;
  border-width: 10px;
  border-color: transparent #2d2d2d transparent transparent;
}

#plus_icon {
  margin: 0px 4px;
  top: 2px;
}

#help_argument_options {
  position: absolute;
  top: 145px;
  right: -68px;
}

.help-faded {
  color: #848383;
}

.help-faded:hover {
  cursor: default;
  background-color: #f1f1f1 !important;
}


.help_attackTooltip {
  position: absolute;
  top: 67px;
  right: -265px;
  width: 251px;
}

.help_attackTooltip::after {
  top: 10px;
  left: -20px;
  border-width: 10px;
  border-color: transparent #2d2d2d transparent transparent;
}

.like_relation_tooltip {
  position: absolute;
  top: 68px;
  left: -395px;
  width: 384px;
  text-align: justify;
}

.like_relation_tooltip::after {
  bottom: 100%;  /* At the top of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-color: transparent transparent #2d2d2d transparent;
}

.explain_attack_weight_tooltip {
  position: absolute;
  top: -82px;
  right: -283px;
  width: 521px;
  text-align: justify;
}

.explain_attack_weight_tooltip::after {
  top: 100%; /* At the bottom of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-color: #2d2d2d transparent transparent transparent;
}

.ok.center {
  bottom: 13px;
  left: 535px;
  cursor: pointer;
  pointer-events: all;
}

.ok.center.explainDrag {
  left: 516px;
  bottom: 2px;
}

.drag_tooltip {
  position: absolute;
  top: 60px;
  text-align: justify;
}

.ok.center.drag {
  left: 296px;
}

.explain_drag_tooltip .explainDrag {
  left: 516px;
}

.explain_drag_tooltip {
  position: absolute;
  top: 60px;
}

.zoom_tooltip {
  position: absolute;
  top: 60px;
}

.ok.center.zoom {
  left: 615px;
  bottom: 3px;
}

.fitToScreen_tooltip {
  position: absolute;
  bottom: 83px;
  right: 28px;
  width: 287px;
  text-align: justify;
}

.fitToScreen_tooltip::after {
  top: 87px;
  right: 11px;
  margin-left: -5px;
  border-color: #2d2d2d transparent transparent transparent;
}

.pin_tooltip {
  position: absolute;
  bottom: 172px;
  right: -120px;
  width: 305px;
  text-align: justify;
}

.pin_tooltip::after {
  top: 100%; /* At the bottom of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-color: #2d2d2d transparent transparent transparent;
}

.ok.center.pin {
  left: 318px;
  bottom: 25px;
}

.pin_tooltip img {
  height: 24px;
  margin: 0px 5px;
  -webkit-filter: invert(1);
          filter: invert(1);
}

/* PLACEHOLDERS */
.placeholder {
  -webkit-animation: fading 1.5s infinite;
          animation: fading 1.5s infinite;
}

@keyframes fading {
  0% {
    opacity: .5;
  }

  50% {
    opacity: .7;
  }

  100% {
    opacity: .5;
  }
}

.title-placeholder {
  width: 120px;
  opacity: 0.5;
  height: 30px;
  border-radius: 100px;
  background-color: #414141;
  margin-bottom: 8px;
}

.seeAll-placeholder {
  width: 70px;
  opacity: 0.5;
  height: 30px;
  border-radius: 100px;
  background-color: #00bfff;
}

.debate-placeholder {
  max-width: 240px;
  min-width: 190px !important;
  height: 230px;
  background-color: #d5d5d5;
  border-radius: 5px;
}

/* LOGOUT HOMEPAGE */
#contentLogout {
}

.first-section {
  height: 700px;
  background: #5CA3DD;
}

.first-section h1 {
  color: white;
  font-family: 'Helvetica Neue', sans-serif;
  font-size: 48px;
  font-weight: bold;
  letter-spacing: -1px;
  line-height: 1;
  text-align: center;
  text-shadow: 0px 0px 6px #37689047;
}

.first-section h2 {
  color: white;
  font-family: 'Open Sans', sans-serif;
  font-size: 28px;
  font-weight: 300;
  line-height: 32px;
  margin-top: 20px;
  text-align: center;
  text-shadow: 0px 0px 6px #37689038;
}

.second-section {
  height: 500px;
  background: #fafafa;
}

.second-section h2 {
  text-align: left;
  font-size: 39px;
  font-weight: 500;
  color: #686868;
  margin-top: 40px;
}

.s2-container {
  width: 1020px;
  justify-content: space-evenly;
  margin-top: 50px;
}

.s2-img {
  width: 600px;
}

.s2-img img {
  width: 100%;
}

.s2-info {
  width: 230px;
}

.s2-info p {
  text-align: justify;
  font-size: 17px;
  line-height: 30px;
  font-family: "Raleway", "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #686868;
  font-weight: 500;
}

.third-section {
  height: 478px;
}

.third-section h2 {
  text-align: left;
  font-size: 39px;
  font-weight: 500;
  color: #686868;
  margin-top: 40px;
}

.s3-container {
  width: 800px;
  margin-top: 50px;
}

.s3-div {
  width: 300px;
}

.s3-div p {
  margin-top: 30px;
  text-align: center;
  font-size: 17px;
  line-height: 30px;
  font-family: "Raleway", "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #686868;
  font-weight: 500;
}

.s3-img {
  line-height: 30px;
}

.s3-div img {
  width: 100%;
}

.s3-img span {
  line-height: 30px;
  color: #000000;
}

#colorBar{
  display: flex;
  position: relative;
  width: 400px;
  margin: 0px 10px;
  background-color: transparent;
  height: 30px;
  border: 1px solid lightgrey;
  border-radius: 5px;
}

#color1bar{
  width:10%;
  float:left;
  background-color: #90F5FE;
  z-index: 22;
}
#color2bar{
  width:10%;
  float:left;
  background-color: #88E9F9;
  z-index: 22;
}

#color3bar{
  width:10%;
  float:left;
  background-color: #81DDF4;
  z-index: 22;
}

#color4bar{
  width:10%;
  float:left;
  background-color: #7AD2F0;
}

#color5bar{
  width:10%;
  float:left;
  background-color: #72C6EB;
}

#color6bar{
  width:10%;
  float:left;
  background-color: #6BBAE6;
}

#color7bar{
  width:10%;
  float:left;
  background-color: #64AFE2;
}

#color8bar{
  width: 10%;
  float: left;
  background-color: #5CA3DD;
}

#color9bar{
  width: 10%;
  float: left;
  background-color: #5597D8;
}

#color10bar{
  width: 10%;
  float: left;
  background-color: #4E8CD4;
}

.special {
  top: 78px;
  font-size: 17px;
  line-height: 30px;
  font-family: "Raleway", "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #686868;
  font-weight: 500;
  width: 490px;
}

.fourth-section {
  height: 540px;
  background: #fafafa;
}

.fourth-section h2 {
  text-align: left;
  font-size: 39px;
  font-weight: 500;
  color: #686868;
  margin-top: 40px;
}

.s4-container {
  width: 1050px;
  margin-top: 50px;
}

.s4-div {
  width: 160px;
  height: 200px;
}

.s4-div p {
  margin-top: 20px;
  text-align: justify;
  font-size: 17px;
  line-height: 30px;
  font-family: "Raleway", "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #686868;
  font-weight: 500;
  width: 255px;
}

.s4-div p.title {
  font-weight: 600;
  text-align: center;
}

.s4-div p.lower {
  margin-top: 45px;
}

.s4-div p.higher {
  position: relative;
  bottom: 67px;
}

.s4-div p.higher2 {
  position: relative;
  bottom: 40px;
}


.s4-div img {
  width: 150%;
}

.s4-div .drag {
  width: 192px;
  position: relative;
  bottom: 40px;
}

.s4-div .browseDots {
  width: auto;
}

.clearAllBtn {
  font-weight: 500;
  color: #505050;
}

/* TREE GRAPH */
.node circle {
  fill: #fff;
  stroke: steelblue;
  stroke-width: 3px;
}

.node text {
  font: 12px sans-serif;
}

.link {
  fill: none;
  stroke: #ccc;
  stroke-width: 2px;
}

.addArgumentHelper {
  position: absolute;
  display: none;
  border: 1px solid #CCC;
  white-space: nowrap;
  font-family: sans-serif;
  background: #FFF;
  border-radius: 5px;
  top: 30px;
  z-index: 1000;
  margin: 0 auto;
  left: 0;
  right: 0;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-weight: 500;
  cursor: default;
  box-shadow: 0px 0px 13px 0px rgba(114, 114, 114, 0.2);
}

.addArgumentHelper.show {
  display: flex;
  -webkit-animation: expanding 0.6s;
          animation: expanding 0.6s;
}

@-webkit-keyframes expanding {
  0% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8)
  }

  50% {
    -webkit-transform: scale(1.15);
            transform: scale(1.15);
  }

  100% {
    -webkit-transform: scale(1);
            transform: scale(1)
  }
}

@keyframes expanding {
  0% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8)
  }

  50% {
    -webkit-transform: scale(1.15);
            transform: scale(1.15);
  }

  100% {
    -webkit-transform: scale(1);
            transform: scale(1)
  }
}

@-webkit-keyframes expanding2 {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1)
  }
  12% {
    -webkit-transform: scale(1.15);
            transform: scale(1.15)
  }
  24% {
    -webkit-transform: scale(1);
            transform: scale(1)
  }
}

@keyframes expanding2 {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1)
  }
  12% {
    -webkit-transform: scale(1.15);
            transform: scale(1.15)
  }
  24% {
    -webkit-transform: scale(1);
            transform: scale(1)
  }
}

.cancelIcon {
  color: #7a7a7a;
  cursor: pointer;
}

.cancelIcon:hover {
  color: black;
}

.light-bold {
  font-weight: 500;
  color: #777777;
  font-size: 15px;
}

.full-width {
  background-color: #007bff;
}

.argumentPrevDiv {
  background-color: #f8f8f8;
  height: 35px;
  border-radius: 5px;
  /*box-shadow: 0px 0px 3px 2px rgba(47, 47, 47, 0.25);*/
  border: solid 1px #a8a8a8;
}

.argumentPrevDiv:hover {
  box-shadow: 0px 0px 1px 1px rgba(47, 47, 47, 0.45);
}

.onHoverOpacity {
  opacity: 0.5;
}

.onHoverOpacity:hover {
  cursor: pointer;
  opacity: 1;
}

.argPrevText {
  max-width: 60%;
}

.disabledButton {
  pointer-events: none;
}

.image-upload img {
  height: 35px;
}

.cancel:hover {
  text-decoration: underline;
}

.signInClickable {
  cursor: pointer;
  text-decoration: underline;
  color: #1ca4ff;
}

.color-picker-debate {
  border-radius: 50%;
  width: 25px;
  height: 25px;
  box-shadow: 0px 0px 1px 1px rgba(60, 60, 60, 0.61);
  cursor: pointer;
}

.color-picker-debate.selected {
  box-shadow: 0px 0px 0px 3px rgba(55, 55, 55, 0.49);
}

.color-picker-debate:hover:not(.selected) {
  box-shadow: 0px 0px 1px 2px rgba(60, 60, 60, 0.61);
}

.thumb-picker-debate {
  border-radius: 5px;
  width: 75px;
  height: 53px;
  padding: 6px;
  border: solid 1px #b1b1b1;
  cursor: pointer;
}

.thumb-picker-debate:hover, .thumb-picker-debate2:hover {
  box-shadow: 0px 0px 1px 1px rgba(60, 60, 60, 0.61);
}

.thumb-picker-debate img {
  width: 41px;
}

.thumb-picker-debate2 {
  border-radius: 4px;
  width: 55px;
  height: 55px;
  padding: 6px;
  border: solid 1px #b1b1b1;
  cursor: pointer;
}

.thumb-picker-debate2 img {
  width: 100%;
}
.forceGraph_container__1J1m1 {
    width: 100%;
    height:100%;
    position: relative;
    background-color: rgb(238, 238, 238);
}

.forceGraph_container__1J1m1 svg {
    display: block;
    width: 100%;
    height: 100%;
}

