.container {
    width: 100%;
    height:100%;
    position: relative;
    background-color: rgb(238, 238, 238);
}

.container svg {
    display: block;
    width: 100%;
    height: 100%;
}
